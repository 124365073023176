const initialState = {
  product_details: null,
  loader: false
};

export default function(state = initialState, action) {
  switch (action.type) {
      case 'GET_PRODUCT':
          return {
              ...state,
              product_details: action.data
          };
      default:
          return {
              ...state,
              error_details: '',
              location: ''
          };
  }
}
